var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _vm.loaderIsVisible ? _c("winbox-global-progress") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-header" },
        [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _vm.isAuthorized
                ? _c("router-link", { attrs: { to: { path: "/" } } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.logoImg,
                        width: "40",
                        height: "40",
                        alt: "Winmo"
                      }
                    })
                  ])
                : _c("img", {
                    attrs: {
                      src: _vm.logoImg,
                      width: "40",
                      height: "40",
                      alt: "Winmo"
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _vm.isAuthorized
            ? _c(
                "div",
                { staticClass: "main-header-block" },
                [_c("winbox-app-header")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("winbox-global-error-notification")
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "main-content-wrap" }, [
        _vm.isAuthorized
          ? _c(
              "div",
              { staticClass: "main-menu" },
              [
                _c("winbox-sidebar-menu", {
                  attrs: {
                    filterOptions: _vm.filterOptions,
                    searchPage: _vm.searchPage
                  },
                  on: { "open-popup-filter": _vm.openPopupFilter }
                }),
                _vm._v(" "),
                _vm.showAvatar ? _c("winbox-user-avatar") : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "main-content",
            class: { "profile-page": _vm.isProfilePage },
            style: [_vm.isEntityPage ? { marginLeft: 220 + "px" } : ""]
          },
          [
            _vm.isAuthorized
              ? _c(
                  "div",
                  { staticClass: "breadcrumbs-wrap" },
                  [_c("winbox-breadcrumbs")],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isReady ? _c("router-view") : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "main-modal-bg" }),
      _vm._v(" "),
      _c("notifications", { attrs: { group: "main" } }),
      _vm._v(" "),
      _c("winbox-popover", {
        attrs: {
          type: _vm.selectedFilterType,
          searchPage: _vm.searchPage,
          selectedFilters: _vm.selectedFilters,
          topPosition: _vm.topPosition
        },
        on: {
          "add-filter": _vm.addFilter,
          "remove-filter": _vm.removeFilter,
          "select-all": _vm.selectAll,
          "remove-all": _vm.removeAll,
          "check-filter-option": _vm.checkFilterOption,
          "close-popup-filter": _vm.closePopupFilter
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }