<template>
  <div class="admin-users">
    <winbox-company-header
      :title="mainTitle"
      class="main-title"
      @changed="openModal"
    />
    <winbox-data-table
      class="main-data-table"
      :data="dataset"
      :columns="columns"
      :options="tableOptions"
      :slots="specificSlots"
    >
      <template :slot="tableProps.fname" slot-scope="data">
        <winbox-input
          placeholder="First Name"
          v-model.trim="data.row[tableProps.fname]"
          :disabled = isDisabled(data.row)
        />
      </template>
      <template :slot="tableProps.lname" slot-scope="data">
        <winbox-input
          placeholder="Last Name"
          v-model.trim="data.row[tableProps.lname]"
          :disabled = isDisabled(data.row)
        />
      </template>
      <template :slot="tableProps.role" slot-scope="data">
        <winbox-select
          placeholder="Role"
          v-model="data.row[tableProps.role]"
          :options="possibleRoles"
          :disabled = isDisabled(data.row)
        />
      </template>
      <template :slot="tableProps.email" slot-scope="data">
        <winbox-input
          placeholder="Email"
          v-model.trim="data.row[tableProps.email]"
          :disabled = isDisabled(data.row)
        />
      </template>
      <template :slot="tableProps.status" slot-scope="data">
        <winbox-select
          placeholder="Status"
          v-model="data.row[tableProps.status]"
          :options="possibleStatuses"
          :disabled = isDisabled(data.row)
        />
      </template>
      <template :slot="tableProps.created_at" slot-scope="data">
        {{ data.row[tableProps.created_at] | winboxDatetime }}
      </template>
      <template :slot="tableProps.options" slot-scope="data">
        <button
          v-if="data.row.id != -1 && isDisabled(data.row)"
          class="button button-remove"
          @click="onDeleteUser(data.row)"
        >
          <winbox-icon icon="trash" :color="Colors.system.error" />
        </button>
        <button
          v-if="data.row.id != -1 && isDisabled(data.row)"
          class="button button-edit"
          @click="editMode(data.row)"
        >
          <winbox-icon icon="edit" :color="Colors.system.primaryLight" />
        </button>
        <button
          v-if="data.row.id != -1 && !isDisabled(data.row)"
          class="button button-edit"
          @click="editUser(data.row)"
        >
          <winbox-icon icon="check" :color="Colors.system.primaryLight" />
        </button>
        <button
          v-if="data.row.id != -1 && !isDisabled(data.row)"
          class="button button-edit"
          @click="notEditMode(data.row)"
        >
          <winbox-icon icon="times" :color="Colors.system.error" />
        </button>
      </template>
    </winbox-data-table>
    <winbox-modal
      :ref="createModal"
      :name="createModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Create new user"
      save-text="Create"
      @on-cancel="onCancel"
      @on-save="onSave"
    >
      <winbox-create-user
        ref="createUserRef"
        v-if="newUser && isCreateNewLoaded"
        :data="newUser"
      />
      <winbox-large-indicator v-if="!isCreateNewLoaded" />
    </winbox-modal>
    <winbox-modal
      :ref="deleteModal"
      :name="deleteModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Delete user"
      save-text="Ok"
      @on-cancel="onDeleteCancel"
      @on-save="onDeleteAccept"
    >
      <p v-if="isDeleteLoaded">Are you sure?</p>
      <winbox-large-indicator v-if="!isDeleteLoaded" />
    </winbox-modal>
  </div>
</template>

<script>
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxCreateUser from "@/components/Organisms/WinboxCreateUser";
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import { Colors } from "@/Colors";
import { UserStatuses } from "@/constants/UserStatuses";
import * as AuthApi from "@/api/auth/auth";

const tableProps = {
  fname: "first_name",
  lname: "last_name",
  role: "role",
  email: "email",
  status: "status",
  created_at: "current_sign_in_at",
  options: "__options"
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "AdminUsers",
  components: {
    WinboxInput,
    WinboxSelect,
    WinboxLargeIndicator,
    WinboxCompanyHeader,
    WinboxDataTable,
    WinboxModal,
    WinboxCreateUser,
    WinboxIcon
  },
  data() {
    return {
      isLoaded: false,
      Colors: Colors,
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.fname]: "First Name",
          [tableProps.lname]: "Last Name",
          [tableProps.role]: "Role",
          [tableProps.email]: "Email",
          [tableProps.status]: "Status",
          [tableProps.created_at]: "Last Login",
          [tableProps.options]: ""
        },
        orderBy: { column: [tableProps.role] },
        sortable: [...mainTableColumns],
        filterable: [...mainTableColumns]
      },
      specificSlots: [
        tableProps.fname,
        tableProps.lname,
        tableProps.role,
        tableProps.email,
        tableProps.created_at,
        tableProps.status,
        tableProps.options
      ],
      createModal: "createModal",
      deleteModal: "deleteModal",
      isCreateNewLoaded: true,
      isDeleteLoaded: true,
      newUser: null,
      usersDataUpdate: [],
      timeout: null,
      isEditMode: false,
      selectedRowID: null
    };
  },
  computed: {
    mainTitle() {
      return `Users (${this.dataset.length})`;
    },
    allUsers() {
      return this.$store.getters.USERS;
    },
    possibleRoles() {
      return this.$store.getters.USER_ROLES.map(item => {
        return {
          id: item.id,
          label: item.role,
          value: item.id
        };
      });
    },
    possibleStatuses() {
      return Object.values(UserStatuses);
    },
    dataset() {
      return this.modifyData(this.allUsers);
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.$store.dispatch("getUserRoles");
      await this.$store.dispatch("getUsers");
    },
    modifyData(data) {
      this.isLoaded = true;
      let result = data.map(item => {
        return {
          ...item,
          role: {
            ...item.role,
            label: this.getRoleLabel(item),
            value: this.getRoleValue(item)
          },
          status: UserStatuses[item.status]
        };
      });
      this.isLoaded = false;
      return result;
    },
    getRoleLabel(item) {
      // @TODO: remove when set default role
      if (item.role) {
        return item.role.role;
      }
      return "";
    },
    getRoleValue(item) {
      if (item.role) {
        return item.role.id;
      }
      return 0;
    },
    openModal() {
      this.newUser = this.prepareNewEntity();
      this.$refs[this.createModal].show();
    },
    onCancel() {
      this.$refs[this.createModal].hide();
    },
    onSave() {
      const vm = this.$refs.createUserRef;
      const $v = vm.$v;

      $v.$touch();

      if (!$v.$error) {
        this.isCreateNewLoaded = false;
        const randomPassword = Math.random().toString(36).slice(-10);

        const payload = {
          ...vm.entity,
          password: randomPassword,
          password_confirmation: randomPassword,
          user_role_attributes: {
            role_id: vm.entity.role.id
          }
        };

        this.$store
          .dispatch("createUser", {
            user: payload
          })
          .then(() => {
            this.isCreateNewLoaded = true;
            AuthApi.resetPassword({ email: vm.entity.email })
              .then(response => {
                if (response && response.message) {
                  this.emailResetMessage = response.message;
                }
                this.isForgotLoaderVisible = false;
              })
              .catch(e => {
                const data = e.response.data;
                this.isForgotLoaderVisible = false;

                if (data && data.errors) {
                  this.emailResetErrorMessage = this.constructErrorMessage(
                    data.errors
                  );
                }
              });
            this.onCancel();
          })
          .catch(e => {
            this.newUser = payload;
            this.isCreateNewLoaded = true;
            let errors = e.response.data.errors;
            if (errors) {
              Object.entries(errors).forEach(([key, value]) => {
              value.forEach( error => { this.$notify({ type: 'error', text: `${key} ${value}`, title: 'Error message', group: "main" });} );
            });
            }
            throw e;
          });
      }
    },
    prepareNewEntity() {
      return {
        [tableProps.fname]: "",
        [tableProps.lname]: "",
        [tableProps.email]: "",
        [tableProps.role]: "",
        [tableProps.status]: UserStatuses.active.value
      };
    },
    onChangeRowData(row, key, e) {
      this.dataset.forEach(item => {
        if (item.id === row.id) {
          item[key] = e;
        }
      });

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.tryToSaveUsers(this.dataset);
      }, 500);
    },
    tryToSaveUsers(items) {
      this.usersDataUpdate = [];

      items.forEach((item, index) => {
        if (item.id) {
          this.usersDataUpdate.push(this.preparePayloadObject(item));
        }
      });

      this.checkUsersSaveQueue();
    },
    checkUsersSaveQueue() {
      this.usersDataUpdate.forEach(payloadItem => {
        const foundItem = this.allUsers.find(
          item => item.id === payloadItem.id
        );
        if (
          foundItem &&
          (foundItem[tableProps.fname] !== payloadItem[tableProps.fname] ||
            foundItem[tableProps.lname] !== payloadItem[tableProps.lname] ||
            foundItem[tableProps.status] !== payloadItem[tableProps.status] ||
            foundItem[tableProps.role].id !== payloadItem[tableProps.role] ||
            foundItem[tableProps.email] !== payloadItem[tableProps.email])
        ) {
          this.updateUser(payloadItem.id, payloadItem);
        }
      });
    },
    updateUser(id, payload) {
      const correctPayload = {
        ...payload,
        user_role_attributes: {
          role_id: payload[tableProps.role]
        }
      };
      delete correctPayload[tableProps.role];

      this.$store.dispatch("updateUser", {
        id,
        payload: correctPayload
      });
    },
    preparePayloadObject(item) {
      let result = {
        id: item.id,
        [tableProps.fname]: item[tableProps.fname],
        [tableProps.lname]: item[tableProps.lname],
        [tableProps.email]: item[tableProps.email],
        [tableProps.status]: this.getStatus(item),
        [tableProps.role]: item[tableProps.role].value
      };
      return result;
    },
    getStatus(item) {
      let statusValue = UserStatuses.inactive.value;
      if (item[tableProps.status]) {
        statusValue = item[tableProps.status].value;
      }
      return statusValue;
    },
    onDeleteUser(row) {
      this.selectedRowID = row.id;
      this.$refs[this.deleteModal].show();
    },
    onDeleteAccept() {
      this.deleteUser(this.selectedRowID);
    },
    onDeleteCancel() {
      this.notEditMode();
      this.isDeleteLoaded = true;
      this.$refs[this.deleteModal].hide();
    },
    deleteUser(id) {
      this.isDeleteLoaded = false;
      this.$store.dispatch("deleteUser", {
        id: id
      }).then(data => {
        this.isDeleteLoaded = true;
        this.onDeleteCancel();
      }).catch(e => {
        this.isDeleteLoaded = true;
        let errors = e.response.data.errors;
        if (errors) {
          Object.entries(errors).forEach(([key, value]) => {
          value.forEach( error => { this.$notify({ type: 'error', text: `${key} ${value}`, title: 'Error message', group: "main" });} );
        });
        }
        this.onDeleteCancel();
        throw e;
      });
    },
    editUser(row) {
      let payload = this.preparePayloadObject(row)

      this.updateUser(row.id, payload);
      this.notEditMode(row);
    },
    editMode(row) {
      this.isEditMode = true;
      this.selectedRowID = row.id;
    },
    notEditMode() {
      this.isEditMode = false;
      this.selectedRowID = null;
    },
    isDisabled(row) {
      return row.id != this.selectedRowID || !this.isEditMode;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-title {
  margin: 0 0 1rem;
}
.main-data-table {
  ::v-deep {
    thead {
      th {
        width: 130px;

        &:last-child {
          width: 80px;
        }
      }
    }
  }
}
</style>
